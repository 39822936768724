@media only screen and (max-width: 800px) {
  .digifleet-front {
    width: 90%;
  }
  .main-container{
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 800px){
  .main-container{

    padding-left: 8%;
    padding-right: 8%;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media print {
  .print-only{
    display: block;
  }
}

.print-only{
  display: none;
}


/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}
a:hover + .hover-hide {
  display: block;
}

html, body, #root {
  /* overflow: auto; */
  height: 100%;
}

@media screen and (min-width: 1224px){
  #root{
    display: flex;
    flex-direction: column;
  }
}



body {
  /* background-color: lightslategray !important; */
  background-color: white;
}

button.page-link{
  color: black;
}

code {
  color: #e01a76;
}

form .model-error {
  padding: 0.4em;
  margin-top: 0.4em;
}

input[type="number"] {
  width: 5em;
}

.address-table tbody{
  position: absolute;
  background-color: white;
  border: solid 1px lightgrey;
  border-radius: 0.3em;
}

.blank-button.sm{
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.blank-button{
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.change-names,.change-password .form-control{
  width: 18em;
}
/* Entity lists */
/* .companies,
.vehicles,
.flex-vehicles,
.tracking-units,
.emulators,
.company-users,
.quotas,
.unit-config,
.email-templates,
.exports,
.quotas,
.users {
  border: 1px solid lightgrey;
  border-radius: 0.3em;
  padding: 1em;
} */

.dashboard-container{
  overflow: auto;
}

.dashboard-icon-title .btn {
  float: right;
  margin: 0.3em;
}

.dashboard-icon-title{
  display: inline;
}

.dashboard-icon{
  /* width: 3em; */
  display: inline-block;
  padding: 0.5em;
  margin-right: 0.3em;
  border-right: solid 1px lightgrey;
}

.dashboard-item {
  width: 30%;
  float: left;
  margin: 1em;
  /* padding: 0.5em; */
  
}
.dashboard-item-title .btn {
  float: right;
}

.dashboard-item-title.active {
  padding-bottom: 1em;
  border-bottom: solid 1px lightgrey
}

.dashboard-item-title{
  margin: 0.5em;
}

.dashboard-item-top{
  /* height: 8em; */
}

.dashboard-item.bordered{
  border: solid 1px lightgrey;
  border-radius: 0.3em;
}

.dashboard-item:after{
  clear: both;
}

.dashboard-title-container.active{
  border-bottom: solid 1px lightgrey;
}

.seperate-buttons > .btn{
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.digifleet-front {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.drive-rest {
  border: 1px solid lightgray;
  border-radius: 0.3em;
  padding: 1em;
}

.form-control label {
  width: 20em;
}

.fullscreen {
  height: 100%;
}

.fullscreen-enabled .map-content-container {
  height: 100%;
  width: 100%;
  padding-bottom: 0;
}

.fullscreen-enabled {
  background-color: white;
}

.history-date-selector{
  border: 1px solid lightgrey;
  border-radius: 1em;
  padding: 0.5em;
}

.icon-selector .selector-button{
  float: right;
}

.icon-selector{
  position: fixed;
  z-index: 100;
  padding: 1em;
  background-color: white;
  border: 1px solid lightgray;
}

.inline-checks > label {
  display: inline;
  padding-right: 1em;
}

.inline-checks {
  margin: 0.4em;
}

.inline-inputs input {
  display: inline;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  /* margin: 0 auto; */
}
.leaflet-control {
  overflow-y: auto;
}



.login-form{
  width: 50em;
}

.table{
  margin-bottom: 0;
}

.map-content-container{
  height: 100%;
  /* padding-bottom: 15px; */
}

.map-container{
  flex-grow: 1;
}

.map-vehicles-control{
  display: inline-block;
}

.map-vehicles-control.control-buttons{
  width: 70%;
}

.map-vehicles-control.close-button{
  flex-grow: 1;
  width: 30%;
  padding: 0.3em;
  
}

.close-button button{
  height: 100%;
  float: right;
}

.navbar-icon{
  margin-right: 0.3em;
}

.map-control-grid{
  display: flex;
  /* flex-direction: column; */
}
.map-control-content{
  border: solid 1px lightgray;
  border-radius: 0.5em;
  background-color: white;

  padding: 1em;
}

.map-control-content.top-left{
  border-top-left-radius: 0;
}
.map-control-content.top-right{
  border-top-right-radius: 0;
}
.map-control-content.bottom-left{
  border-bottom-left-radius: 0;
}
.map-control-content.bottom-right{
  border-bottom-right-radius: 0;
}


.map-row-right .btn {
  float: right;
  width: 2.5em;
}

.map-row-right {
  margin: 0.3em;
}

.map-row-right::after {
  content: "";
  display: block;
  clear: both;
}

.modal-body .container {
  width: 100%;
}

.modal-body {
  border: solid 1px lightgrey;
  border-radius: 0.2em;
}
.modal-content {
  padding: 1em;
}
.modal-map{
  height: 70vh;
  width: 100%;
  margin: 0 auto;
}

.nav-tabs {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.nav-tabs {
  padding-bottom: 1px;
}

.navbar-digifleet .nav-link {
  font-weight: 500;
}

.navbar-digifleet {
  background-color: #dc0000;
}
.navbar-digifleet .nav-link{
  color: white !important;
}
.navbar-digifleet .nav-link.active{
  color: lightgray !important;
}

.navmenu-icon{
  display: inline-block;
  margin-right: 0.2em;
}

.vehicle-popup .nav-link{
  width: 50%;
}

.vehicle-popup .nav-link.active{
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-color: #dee2e6 !important;
}


.page-next-icon{
  margin-left: 0.3em;
  display: inline-block;
}
.page-back-icon{
  margin-right: 0.3em;
  display: inline-block;
}
.pager-button {
  margin: 0.1em;
}

.pager-control {
  position: absolute;
}

.qr-code-assign{
  max-width: 20em;
}

.qr-code-image{
  max-width: 10em;
  max-height: 10em;
}

.route-active {
  width: 30em;
}

.route-leg {
  height: 30em;
  padding: 0.2em;
  overflow-y: auto;
}

.route-planner .btn {
  margin-left: 0.2em;
}

.route-search button span {
  text-align: left;
}

.route-search button {
  max-width: 100%;
  border: 1px solid grey;
}

.route-search td {
  max-width: 100%;
  padding-left: 0.5em;
}

.route-search {
  padding: 0.5em;
  border: 1px grey;
  width: 100%;
  position: relative;
}


.route-step {
  border-left: 1px solid black;
  padding: 0.4em;
}

.row input[name="address"] {
  width: 30em;
}

.row label {
  width: 100%;
}

.service-button{
  float: right;
}

thead th{
  background: none!important;
  border: none;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}

.sort-link {
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.sort-link:hover{
  color: rgba(0, 0, 0, 0.7);
}
.sort-link.active{
  color: rgba(0, 0, 0, 0.9);
}

.speed-chart{
  height: 20em;
}

.speed-label-icon{
  margin-right: 0.3em;
  display: inline-block;
}
.speed-label-icon.active{
  color: green;
}
.speed-label-icon.still{
  color: orange;
}
.speed-label-icon.inactive{
  color: red;
}


.speed-label{
  display: inline-block;
}

.vehicle-icon {
  white-space: nowrap;
  font-weight: 800;
  /* height: 2em; */
  max-width: 3em;
  /* margin: 0.1em; */
  display: inline;
}
td .vehicle-icon{
  height: 2em;
  display: block;
}

p .vehicle-icon{
  height: 2em;
  display: inline-block;
}

.vehicle-icon > svg{
  height: 100%;
  width: 100%;
}

.vehicle-icon.active{
  
  border-radius: 0.3em;
  border: 1px solid green;
  background-color: rgba(50,205,50, 0.3);
}


.vehicle-icon.active.still{
  border: 1px solid orange;
  background-color: rgba(226, 191, 33, 0.3);
}
.vehicle-icon span{
  padding: 0.1em;
}

.vehicle-live-icon{
  display: inline;
}

.map-icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}
.map-icon-container.text{
  padding: 0.9em;
}

.icon-director{
  height: 100%;
  width: 1em;
  position: absolute;
}

.icon-director.right, .icon-director.left{
  width: 100%;
  height: 1em;
  /* transform: rotate(90deg); */
}

.icon-director.right, .icon-director.left{
  transform: rotate(90deg);
}

.director-rotater{
  width: 100%;
  height:100%;
}
.director-counter-rotater{
  width: 100%;
  height:100%;
}
.icon-arrow{
  transform: rotate(-45deg);
  height: 1em;
  width: 1em;
  border: solid darkblue;
  border-width: 3px 3px 0 0;
  display: flex;
  align-items: center;
}

.Satellit .icon-arrow{
  border-color: green;
}

.icon-arrow div{
  width: 100%;
  height: 3px;
  background-color: darkblue;
  transform: rotate(-45deg) translateX(25%);
}

.Satellit .icon-arrow div{
  background-color: green;
}

.right .icon-arrow, .left .icon-arrow{
  transform: rotate(-135deg);
}

.icon-rotator{
  width: 100%;
  height:100%;
}

.icon-rotator.down{
  position: absolute;
  bottom: 0;
}

.vehicle-popup {
  width: 25em;
}

.vehicle-tooltip {
  padding: 0px 0.1em 0px 0.1em;
  font-size: 75%;
}

.preferences{
  border: solid 1px lightgray;
  border-radius: 0.3em;
  padding: 1em;
}
.preferences > .section{
  border: solid 1px lightgray;
  border-radius: 0.3em;
  padding: 1em;
}

.icon-size{
  width: 20em;
}

.export-option{
  margin: 0.5em;
}

.export-buttons button{
    margin: 0.2em;
}

.date-selector label{
  margin: 0.2em;
}

.navigation-result{
  margin-top: -1px;
  padding: 0.4em;
  border: solid 1px lightgray;
  border-radius: 0.3em;
  padding: 1em;
}
.navigation-selector .navigation-result{
  border-top-left-radius: 0;
}

.navigation-selector .selected, .proximity-index .selected{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


.button-control{
  display: flex;
  flex-direction: column;
}

.button-control.active .button-control-container button{
  border-radius: 0.25rem 0.25rem 0 0;
}

.button-control-container button{
  float: right;
}

table tr.inactive{
  color: gray;
  background-color: rgba(0, 0, 0, 0.1) !important;

}
table tr.inactive a{
  color: gray;
}

table button{
  margin: 0.1rem;
}

table .blank-button{
  padding: 0.3rem;
}
.error-description-container{
  margin: 5em;
}
#error-description-text{
  padding: 1em;
  border: solid 1px lightgray;
  width: 90%;
}

.Satellit .vehicle-icon{
  background-color: lightgray;
  border-radius: 0.3em;
  border: 1px solid lightgray;
}

.Satellit .vehicle-icon.active{
  
  border-radius: 0.3em;
  border: 1px solid green;
  background-color: rgba(50,205,50, 1);
}


.Satellit .vehicle-icon.active.still{
  border: 1px solid orange;
  background-color: rgba(226, 191, 33, 1);
}


.Satellit .historic-icon{
  color: red;
}


.loading-spinner{
  animation: rotation 2s infinite linear;
  animation-play-state: paused;
}
.loading-spinner.active{
  animation-play-state: running;
}

@keyframes rotation{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}


.tab-pane{
  border: 1px solid lightgray;
  border-radius: 0 0 0.3em 0.3em;
  padding: 1em;
}

.centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.timespan-input input{
  width: 3em;
}
.divider{
  width: 5px;
  height: auto;
  display: inline-block;
}

.descriptor{
  display: inline-block;
  position: absolute;
  background-color: white;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 0.5em;
}



.toggle-section-content{
  display: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0.5em;
}
.toggle-section-content.active{
  display: table;
}

.table-plain tr{
  background-color: white !important;
}
.table-plain td{
  border: none !important;
}

.historic-stop{
  color: red;
}


/* Mobile */

.mobile-layout{
  padding: 0.8em;
  background-color: lightgray;
  min-height: 100%;
  /* height: 100%; */
}

.mobile-layout.map-layout{
  padding: 0;
  height: 100%;
}

.mobile-navbuttons{
  max-width: 15em;
}

.mobile-navbuttons .mobile-navbutton{
  margin: 1em;
}

.mobile-navbuttons button{
  width: 100%
}

.mobile-icon{
  display: inline-block;
  margin: 0 0.2em 0 0.2em;
}

.mobile-company-choice{
  margin: 1em;
}
.mobile-company-choice button{
  width: 100%
}

.mobile-company-selector{
  max-height: 80%;
  overflow-y: auto;
}

.mobile-map{
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1224px){
  .centered{
    height: 100%;
  }
}

.centered-content{
  height: 100%;
}

.mobile-search{
  min-width: 7em !important;
  width: 7em !important;
}
.mobile-search-button{
  float:right;
}
.mobile-map-vehicles{
  display: block;
  max-width: 18em;
}

.mobile-map-vehicles td{
  overflow:hidden;
  max-width: 18em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-tacho{
  max-height: 80%;
  overflow-y: auto;
  margin-top: 1em;
}
.mobile-periodic-history{
  height: 100%;
}
.mobile-history{
  max-height: 60vh;
  overflow-y: auto;
  margin-top: 1em;
}

.mobile-tacho-row{
  max-width: 100%;
}
.mobile-tacho-rowheader, .mobile-history-rowheader{
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}
.mobile-tacho-rowcontent, .mobile-history-content{
  border: 1px solid gray;
  border-radius: 0 0 0.5em 0.5em;
  padding: 0.4em;
  background-color: white;
}
.mobile-tacho-rowcontent label{
  margin: 0;
}
.mobile-tacho-rowcontent td:nth-of-type(2n){
  overflow-wrap: anywhere;
}
.mobile-tacho-rowcontent table{
  margin: 0;
}

.mobile-tacho-section, .mobile-history-section{
  display: inline-block;
  margin-right: 0.7em;
  
}
.mobile-tacho-section.tacho-name{
  overflow:hidden;
  text-overflow: ellipsis;
  width: 100%;
  
}

.mobile-history-section.history-address{
  overflow:hidden;
  text-overflow: ellipsis;
  width: 100%;
}


.mobile-history-select{
  max-height: 70vh;
  overflow-y: auto;
}
.mobile-history-selectoption{
  margin: 0.5em;
}
.mobile-history-selectoption button{
  width: 100%;
}

.mobile-navbuttons img{
  width: 80%;
  margin: auto;
  display: block;
}

.vehicle-select.inactive{
  background-color: lightgray;
}






.mobile-map-options{
  display: flex;
  flex-direction: column;
}
.mobile-map-options button{
  margin-left: 1em;
}

.mobile-map-options .section{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-map-options .section:not(:last-child){
  margin-bottom: 0.5em;
}


.vehicle-tooltip.mobile{
  font-size: 1.2em;
  font-weight: bold;
}

#periodic-history-total-km{
  float: right;
}

.mobile-override{
  position: fixed;
  right: 0.5em;
  bottom: 0.5em;
}

.driving-period{
  display: flex;
  align-items: center;
  gap: 1em;
}

.driving-period time{
  font-weight: bold;
}

.driving-period .section{
  display: flex;
  column-gap: 0.6em;
  flex-wrap: wrap;
}

.driving-period .sub-section{
  display: flex;
  column-gap: 0.6em;
}


.driving-period .section span{
  white-space: nowrap;
}

.driving-period .section .speed-label-icon{
  margin-right: 0;
}


.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.mobile-vehicle-popup{
  width: 14em;
}

.mobile-vehicle-popup .driving-period{
  border: solid 1px lightgrey;
  border-radius: 4px;
}